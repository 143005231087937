<template>
  <div>
    <van-nav-bar
      title="注册邀请码"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      right-text="生成邀请码"
      @click-right="onRightClick"
    />
    <van-row style="margin-top:10px">
        <van-col span="8">注册邀请码</van-col>
        <van-col span="4">状态</van-col>
        <van-col span="12">创建时间</van-col>
    </van-row>
   <van-row v-for="item in dataList" :key="item.id" style="padding-top:5px;">
        <van-col span="8">{{item.invitation_code}}</van-col>
        <van-col span="4" v-if="item.status == 0">未使用</van-col>
        <van-col span="4" v-else>已使用</van-col>
        <van-col span="12">{{item.created_time}}</van-col>
    </van-row>
    <div class="my_pager">
    <van-pagination v-model="currentPage" :page-count="pageCount" mode="simple" @change="changePage" />
    </div>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";
export default {
  data() {
    return {
      dataList: [],
      currentPage: 1,
      pageCount: 1,
    };
  },
  components: {
    CopyRight,
  },
  mounted() {
    this.loadData();
  },

  methods: {
    onClickLeft() {
      this.$router.back();
    },

    onRightClick(){
      let postData = this.$qs.stringify({
            token:window.sessionStorage.getItem("token"),
        });

      axios
        .post("api/member/invitation_code/create", postData)
        .then((res) => {
          if(res.data.code == 0){
            this.$toast("生成邀请码成功");
            this.$router.go(0);
          }else{
            this.$toast(res.data.message);
          }
        })
        .catch((err) => {
          console.log("err-->" + err);
          this.$toast.fail("生成邀请码失败");
        });
    },

    changePage(page){
      this.currentPage = page;
      this.loadData();
    },

    loadData() {
      let token = window.sessionStorage.getItem("token");
      axios
        .get(
          "api/member/invitation_code/list?page=" +
            this.currentPage +
            "&token=" + token
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.dataList = res.data.data.data_list;
            this.pageCount = res.data.data.total_page;
          }else{
            this.$toast.fail(res.data.message);
          }
        })
    },
  },
};
</script>